// Fadein
document.addEventListener("DOMContentLoaded", () => {
  const fadeInEls = document.querySelectorAll(".js-fadein");
  const fadeInElsArr = Array.from(fadeInEls);

  const cb = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("js-fadein-active");
        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    rootMargin: "-50% 0px",
  };

  const io = new IntersectionObserver(cb, options);
  io.POLL_INTERVAL = 100;

  fadeInElsArr.forEach((el) => {
    io.observe(el);
  });
});

// moving particles
if (document.querySelector(".js-moving-circles")) {
  Dots.generate({
    selector: ".js-moving-circles",
    accurancy: 26,
    color: "#009c40",
    size: 6,
    speed: 30000,
    randomOpacity: false,
    randomSize: false,
    randomColor: false,
  });
}

// hamburger menu
const hamburgerMenu = document.querySelector(".js-hamburger");
const menu = document.querySelector(".menu");
hamburgerMenu.addEventListener("click", () => {
  hamburgerMenu.classList.toggle("hamburger-btn--active");
  menu.classList.toggle("menu--active");
  document.body.classList.toggle("body--menu-active");
});

// slider
import Swiper from "swiper/bundle";

const slider = new Swiper(".swiper-container", {
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  slidesPerView: 1,
  spaceBetween: 0,

  breakpoints: {
    769: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
  },
});

// アンカーリンクスクロール
$(document).ready(function () {
  //URLのハッシュ値を取得
  var urlHash = location.hash;
  //ハッシュ値があればページ内スクロール
  if (urlHash) {
    //スクロールを0に戻す
    $("body,html").stop().scrollTop(0);
    setTimeout(function () {
      //ロード時の処理を待ち、時間差でスクロール実行
      scrollToAnker(urlHash);
    }, 100);
  }

  //通常のクリック時
  $('a[href^="#"]').click(function () {
    //ページ内リンク先を取得
    var href = $(this).attr("href");
    //リンク先が#か空だったらhtmlに
    var hash = href == "#" || href == "" ? "html" : href;
    //スクロール実行
    scrollToAnker(hash);
    //リンク無効化
    return false;
  });

  // 関数：スムーススクロール
  // 指定したアンカー(#ID)へアニメーションでスクロール
  function scrollToAnker(hash) {
    var target = $(hash);
    var header = $(".header");
    var headerHeight = header.height();
    var position = target.offset().top - headerHeight;
    $("body,html").stop().animate({ scrollTop: position }, 500);
  }
});
